body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #949699;
}

::-webkit-scrollbar-thumb {
  background-color: #D40000;
  border-radius: 12px;
}